<template>
  <div>
     <el-card>
      <search-form
        ref="searchForm"
        :topVisible="false"
        :tabData="tabData"
        labelWidth="120px"
        :searchFormData="searchFormData"
        @search="search" @tabChange="tabChange"
      >
      </search-form>
      <create-table
        ref="createTable"
        :operationColWidth="170"
        :autoWidth="true"
        :operateVisible="operateVisible"
        :loading="loading"
        :btnType="[]"
        :total_count="pageData.total_count ? pageData.total_count : 0"
        :current_page.sync="pageData.page"
        :papeSize.sync="pageData.per_page"
        :topButtonVisible="true"
        :tableData="tableData"
        :tableDataItem="tableDataItem"
        :pageVisible="true"
        :pageData="pageData"
        @forwardToPage="forwardToPage"
        :checkBoxVisible="true" @handleSort="handleSort"
        @handleNewTab="handleNewTab($event, 'storesManagerDetail')"
        @selectionChange="selectionChange"
      >
        <template #topButton>
          <div class="operate__wrapper">
            <el-select v-model="value1" placeholder="Select Action">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-caret-right" @click="handleOperate"></el-button>
          </div>
          <!-- <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton> -->
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" v-if="scope.row.network_status.value === 'LONG-TERM UNAVAILABLE'" type="primary" @click="hanleMessage">Reset</el-button>
          <el-button class="margintop10" v-if="scope.row.hardware_status.value === 'High Temperature'" type="primary" @click="openDialogForm">Set Charging Profile</el-button>
          <el-button class="margintop10" v-if="scope.row.hardware_status.value === 'Firmware Update Failed'" type="primary" @click="hanleMessage">Update Firmware</el-button>
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <el-dialog title="Set Charging Profile" v-if="dialogFormVisible" width="80%" :visible.sync="dialogFormVisible">
      <chargingSchedule></chargingSchedule>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="hanleMessage(); dialogFormVisible = false">Set</el-button>
      </div>
    </el-dialog>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import apis from '@/services'
import jsonData from './jsonData.js'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import chargingSchedule from '@/views/chargingPointManager/add/component/chargingSchedule.vue'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'Transactions',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    dialogEffect,
    chargingSchedule
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      dialogFormVisible: false,
      options1: [{
        value: 'Reset',
        label: 'Reset'
      }, {
        value: 'Update Firmware',
        label: 'Update Firmware'
      }, {
        value: 'Set Charging Profile',
        label: 'Set Charging Profile'
      }, {
        value: 'Get Composite Schedule',
        label: 'Get Composite Schedule'
      }, {
        value: 'Get Diagnostics',
        label: 'Get Diagnostics'
      }, {
        value: 'Triggle Message',
        label: 'Triggle Message'
      }, {
        value: 'Clear cache',
        label: 'Clear cache'
      }, {
        value: 'Set Security Profile',
        label: 'Set Security Profile'
      }, {
        value: 'Reset Security Profile',
        label: 'Reset Security Profile'
      }, {
        value: 'Send DataTransfer message',
        label: 'Send DataTransfer message'
      }, {
        value: 'Create Template',
        label: 'Create Template'
      }, {
        value: 'Change Availability',
        label: 'Change Availability'
      }, {
        value: 'Disconnect',
        label: 'Disconnect'
      }, {
        value: 'Export Charge Points',
        label: 'Export Charge Points'
      }],
      value1: '',
      selectData: []
    }
  },
  created () {
    this.queryData(apis.AssetApi.getChargePointSetup, { type: 'fault_diagnosis' })
  },
  methods: {
    selectionChange (val) {
      this.selectData = val
    },
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page, type: 'fault_diagnosis' })
      this.loading = true
      apis.AssetApi.getChargePointList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    handleClick (type, data) {
      if (type.action === 'create') {
        this.$router.push({
          name: 'chargingPointAdd'
        })
      }
      if (type.action === 'edit') {
        this.$router.push({
          name: 'chargingPointAdd',
          params: {
            id: data.id.value
          }
        })
      }
      if (type.action === 'view') {
        this.$router.push({
          name: 'chargingPointDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type.action === 'destroy') {
        this.deleteClick(data, apis.AssetApi.deleteChargePointRecord)
      }
    },
    forwardToPage (data, name) {
      if (data.model_name && data.model_name === 'evse') {
        this.$router.push({
          name: 'EVSEsDetail',
          params: {
            id: data.id
          }
        })
      } else if (data.model_name && data.model_name === 'location') {
        this.$router.push({
          name: 'locationDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOperate (status = false) {
      if (this.selectData.length < 1) {
        this.$message({
          message: 'Please Select Data.',
          type: 'error'
        })
        return
      }
      if (this.value1 === '') {
        this.$message({
          message: 'Please Select Action.',
          type: 'error'
        })
        return
      }
      if (this.value1 === 'Set Charging Profile') {
        this.openDialogForm()
      } else {
        this.hanleMessage()
      }
    },
    openDialogForm () {
      this.dialogFormVisible = true
    },
    hanleMessage () {
      this.$store.state.app.loading = this.$loading(loading)
      setTimeout(() => {
        this.$store.state.app.loading.close()
        this.$message({
          message: 'The charging point is currently unavailable.',
          type: 'error'
        })
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.operate__wrapper {
    border-top: 1.5px solid #EBEEF5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px;
    padding-top: 12px;
    .el-select {
      // border: 1.5px solid #409eff;
      border-radius: 6px;
      width: 200px;
      margin-right: 4px;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #409eff;
    }
    .el-select .el-input__inner:focus {
      border-color: #409eff;
    }
  }
</style>
