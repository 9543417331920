// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'location',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'system_status',
    label: '',
    Object: 'value',
    width: '200'
  },  {
    prop: 'network_status',
    label: '',
    Object: 'value',
    width: '200',
    redBg: true
  }, {
    prop: 'hardware_status',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'evses',
    label: '',
    Object: 'value',
    width: '180'
  }]
}
